import * as React from 'react'
import { CircleIcon, LinkedInSVG } from '../../Components/SVGs'


const ImageCard = ({ src, label, description }) => (
  <div className="image-card">
    <img src={src} alt="client" loading='lazy'/>
    <div className="content">
      <span>{label}</span>

      <div className="text">
        <p>{description}</p>
      </div>
    </div>
  </div>
)

const TeamSection = ({ src, name, position, href , isVictor }) => (
  <div className="team-card">
    <div className="team-circle">
      <CircleIcon />
      <img src={src} alt="" loading='lazy' style={isVictor &&{ width: 210, borderRadius: "50%"}}/>
    </div>
    <div className="info">
      <h5>{name}</h5>
      <p>{position}</p>
      <a href={href} target="_blank" rel="noreferrer">
        <LinkedInSVG />
      </a>
    </div>
  </div>
)

const AboutIndex = () => {
 
  return (
    <section className="aboutPage">
      <div className="aboutPage__content">
        <div className="line-text container">
          <div className="title">
            <h5>The Company</h5>
          </div>
        </div>
        <div className="aboutPage__content-text-2">
          <p>
            Since 2016, we have invested in research & development and in our
            team to make workplaces fun, flexible and less rigid whiles
            increasing productivity.
          </p>
        </div>
        <div className="aboutPgae__content-card">
          <div className="aboutPage__content-card-img">
            <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606056/webbermill-website-assets/static/banner/about_ffpzmz.png" alt="about" loading='lazy'/>
          </div>
          <div className="aboutPage__content-card-text container">
            <p>We are committed to</p>
            <h5>TRANSFORMING, INSPIRING & ENRICHING LIVES</h5>
            <p>
              In the spirit of empathy for users, innovation and service, we’re
              impacting individual lives and disrupting core business
              structures. We provide range of flexible and easy to use
              enterprise applications which together form a complete suite of
              tools for businesses at all stages. We think every business
              regardless of the industry should have a software that work-out
              the complexity, revealing new insights and serving you with the
              information to stay ahead.
            </p>
          </div>
        </div>
        <div className="aboutPage__content-client">
          <div className="line-text container">
            <div className="title">
              <h5>Our Culture</h5>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-10 mx-auto">
                <div className="row client-img">
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <ImageCard
                      src="https://res.cloudinary.com/webbermill/image/upload/v1679606059/webbermill-website-assets/static/images/client1-old_ggpfu5.jpg"
                      alt=""
                      label="Team-First"
                      description="Building a team of friends with regular socialization outside of work. We listen and act on thoughtful feedback from employees."
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <ImageCard
                      src="https://res.cloudinary.com/webbermill/image/upload/v1679606041/webbermill-website-assets/static/images/client2_cy5w4t.jpg"
                      label="Opportunity"
                      description="Through continuous investment, we empower team members to dare to dream. 
Be creative, innovative and most importantly be yourself"
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-4">
                    <ImageCard
                      src="https://res.cloudinary.com/webbermill/image/upload/v1679606040/webbermill-website-assets/static/images/client3_ldihli.jpg"
                      label="Diversity"
                      description="We believe our business is to enrich and inspire lives therefore 
we represent people of all walks of lives, promote inclusivity at 
all levels."
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="" style={{ marginTop: -70 }}>
              <div className="col-sm-12 col-md-12 col-lg-7 mx-auto">
                <div className="row client-img">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <ImageCard
                      src="https://res.cloudinary.com/webbermill/image/upload/v1679606041/webbermill-website-assets/static/images/client4_sz5vvl.jpg"
                      label="OPENNESS"
                      description="At any giving point in time, we ensure that the state of the 
company is known to all within. 
We encourage employees to interact across the entire organization."
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <ImageCard
                      src="https://res.cloudinary.com/webbermill/image/upload/v1679606041/webbermill-website-assets/static/images/client5_j8uqh8.jpg"
                      label="VULNERABILITY"
                      description="We truly believe and build enabling space for people to be their true selves, share their challenges and gifts at the workplace with colleagues."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutPage__content-team">
          <div className="line-text container">
            <div className="title">
              <h5>Our Team</h5>
            </div>
          </div>
          <div className="container">
            <div className="row team-members mt-5">
              <div className="col-sm-12 col-md-6 col-lg-4 text-center first-circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679606046/webbermill-website-assets/static/team/ck_rugsid.webp"
                  name="Charles Kotoko"
                  position="Creative Director"
                  href="https://www.linkedin.com/in/charles-kodzo-kotoko/"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center second-circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679606047/webbermill-website-assets/static/team/ion_oznemi.webp"
                  name="Saviour Yao Dorvlo"
                  position="CTO"
                  href="https://www.linkedin.com/in/dorvlo-savior/"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center third-circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679606047/webbermill-website-assets/static/team/eli_tmhr7b.webp"
                  name="Eric Elikplim Dorvlo"
                  position="Curator/Support"
                  href="https://www.linkedin.com/in/eriq-elikplim/"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center first-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679606046/webbermill-website-assets/static/team/edward_gyh4f4.webp"
                  name="Edward Adjaloko Nagai"
                  position="Front End Developer"
                  href="https://www.linkedin.com/in/edward-adjaloko-122157156/"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center second-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679665850/webbermill-website-assets/static/team/kamlio_hryhks.png"
                  name="Mamudu Kamilo"
                  position="Backend Developer"
                  href=""
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center third-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679606047/webbermill-website-assets/static/team/peal_vrgkfh.webp"
                  name="Precious Adiembrah"
                  position="Front Desk"
                  href="https://www.linkedin.com/in/adiembrah-precious/"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center second-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679665850/webbermill-website-assets/static/team/henry_ufiutc.png"
                  name="Henry Amihere"
                  position="UI/UX Designer"
                  href=""
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center first-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679665850/webbermill-website-assets/static/team/issac_cfwy8y.png"
                  name="Isaac Obeng"
                  position="Front End Developer"
                  href=""
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center second-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679606045/webbermill-website-assets/static/team/francis_gjdig6.webp"
                  name="Francis Teye"
                  position="Backend Developer"
                  href="https://www.linkedin.com/in/francis-teye-355564176/"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 text-center third-circle circle">
                <TeamSection
                  src="https://res.cloudinary.com/webbermill/image/upload/v1627908221/webbermill-website/img/victor_suwhqe.jpg"
                  name="Victor Atinyo"
                  position="Full Stack & Devops"
                  href="https://www.linkedin.com/in/victor-atinyo-83374377/"
                  isVictor
                />
              </div>

              {/* <div className="col-sm-12 col-md-6 col-lg-4 text-center first-circle  circle mb-5">
                <TeamSection
                  src="/team/dani.webp"
                  name="Daniel Narh"
                  position="Back End Developer"
                  href="https://www.linkedin.com/in/daniel-narh-kpodo/"
                />
              </div> */}
            </div>

           
          </div>
        </div>
        <div className="aboutPage__content-customers">
          <div className="line-text container">
            <div className="title">
              <h5>Our Partners</h5>
            </div>
          </div>
          <div className="container">
            <div className="row client1 ">
              <div className="col-sm-6 col-md-12 col-lg-10 mx-auto text-center">
                <div className="row mt-5 mx-auto align-items-center text-center">
                  <div className="col-sm-6 col-md-4 col-lg-4">
                   <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606035/webbermill-website-assets/static/clients/aws_atjigw.webp" alt="client" width={100} title="Amazon Web Service"/>
                    
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606035/webbermill-website-assets/static/clients/digital_g1dn8h.webp" alt="client" width={150} title="Digital Ocean"/>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                   <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606036/webbermill-website-assets/static/clients/antartic_qm9ksb.png" alt="client" width={120} title="Antartic Ltd."/>
                    
                  </div>
                 
                  <div className="col-sm-6 col-md-4 col-lg-4 client2">
                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606036/webbermill-website-assets/static/clients/jhk_izekuo.webp" alt="client" width={150} title="J. K. Horgle Transport & Co. Ltd."/>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 client2">
                   <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606035/webbermill-website-assets/static/clients/git_orqrnu.webp" alt="client" width={110} title="Github"/>
                  </div>
                   <div className="col-sm-6 col-md-4 col-lg-4 client2">
                    <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606036/webbermill-website-assets/static/clients/herbal-logo_rupfna.png" alt="client" width={180} title="Tinatett Herbal"/>
                  </div>
                   <div className="col-sm-6 col-md-4 col-lg-4 client3">
                     <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606035/webbermill-website-assets/static/clients/google_engb0u.webp" loading="lazy" alt="client" width={150} title="Google"/> 
                  </div>
                   <div className="col-sm-6 col-md-4 col-lg-4 client3">
                     <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606035/webbermill-website-assets/static/clients/kranson_logo_jkjdav.png" loading="lazy" alt="client" width={220} title="Kranson Uniforms"/> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutIndex
