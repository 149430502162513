import React from 'react'
import { FooterComponent } from '../../Components/FooterComponent'
import HeaderComponent from '../../Components/HeaderComponent'
import Layout from '../../Components/Layout'
import Seo from '../../Components/Seo'
import AboutUs from '../../modules/AboutUs/AboutIndex'

const index = () => {
  return (
    <>
      <Seo title="Company | Webbermill" />
      <Layout className="aboutPage" hasProducts={true}>
        <title>About Us</title>
        <HeaderComponent hasProduct />
        <AboutUs />
        <FooterComponent />
      </Layout>
    </>
  )
}

export default index
